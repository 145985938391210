import React from "react";
import logo from "../assets/logo.png";

const Refund = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row">
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12" style={{ padding: "40px" }}>
          <h1>Cancellation, Return and Refund Policy</h1>
        </div>
      </div>
      {/* <img src={PrivacyBanner} alt="privacy-banner" className="bannerImg" /> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Agasthya Nutro Milk Cancellation, Return and Refund Policy</h2>

            <p>
              <span className="privacySpan">1. Cancellation Policy:</span>
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                After making an online payment for a particular Order/
                product/subscription, You as a customer can cancel your delivery
                for a particular day/Order anytime up to the cut-off time (i.e.,
                9 PM of the day preceding the date of actual delivery of the
                product). You may also create a vacation/holiday period on the
                Platform. You can also end the vacation before the cut-off time
                (i.e., 9 PM of the day preceding) when You want to recommence
                the delivery.
              </li>
              <li>
                In the event of an item on Your order being unavailable, We will
                inform you of such unavailability. In such an event You will be
                entitled to a refund in accordance with Our refund policy.
              </li>
              <li>
                We reserve the sole right to cancel Your order in the following
                circumstances:
                <ul>
                  <li>
                    In the event of the designated address falls outside the
                    delivery zone offered by Us;
                  </li>
                  <li>
                    Failure to contact You by phone or email at the time of
                    confirming the Order booking;
                  </li>
                  <li>
                    Failure to deliver Your order due to lack of information,
                    direction, or authorization from You at the time of
                    delivery;
                  </li>
                  <li>
                    Unavailability of all the items ordered by You at the time
                    of booking the Order;
                  </li>
                  <li>
                    In case the delivery partner is not allowed inside your
                    compound, community or society or any other reason beyond
                    control causing movement of the delivery person including
                    but not limited to law and order situation.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">2. Return & Refund policy:</span>{" "}
              Our Return and Refund policy entitles You to return the product at
              the time of delivery if due to some reason if You are not
              satisfied with the quality or freshness of the product. We will
              take the returned product back with Us and issue a refund for the
              value of the returned products which will be credited to Your
              account wallet on the Site.
              <br />
              <br />
              This can be used to pay for your subsequent orders.
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                You shall be entitled to a refund only if You prepay for Your
                order at the time of placing Your order on the Platform and only
                in the event of any of the following circumstances:
                <ul>
                  <li>
                    Your Order packaging has been tampered or damaged or spilled
                    at the time of delivery and before You accepted the
                    delivery;
                  </li>
                  <li>
                    Us canceling Your order due to (A) Your delivery location
                    following outside Our designated delivery zones; (B) Failure
                    to contact You by phone or email at the time of confirming
                    the Order booking; or (C) Failure to contact You by phone or
                    email at the time of confirming the order booking;
                  </li>
                  <li>
                    All refunds will be credited to your Sid’s Farm account
                    wallet. You can also raise a request to transfer the money
                    back to the source. It will take 3-21 working days for the
                    money to show in Your bank account depending on Your bank’s
                    policy. We encourage You to review the listing before making
                    the purchase decision. In case you ordered the wrong item,
                    You shall not be entitled to any return/refund.
                  </li>
                  <li>
                    You will not be required to pay for the wrong item being
                    delivered other than what You had ordered to the extent of
                    value/quantity of the item/s, missing from Your Order at the
                    time of delivery/short delivery.
                  </li>
                  <li>
                    You may return the product if the wrong item is being
                    delivered other than what You had ordered or the item
                    substantially is damaged or deteriorated in quality at the
                    time of delivery. You agree that You shall give Us all the
                    proofs including but not limited to images of products
                    having issues for Us to process your requests.
                  </li>
                  <li>
                    For the customer who chose to end the subscription
                    permanently, the refundable amount will be sent to the bank
                    account given by the customer. In case of any delivery
                    issues, the product will either be replaced or the credit
                    amount will be processed within 24 hours accordingly.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">For any queries contact us at</span>
              <br />
              <br />
              <span className="privacySpan">
                Agasthya Nutro Milk Private Limited
              </span>
              <br />
              <br />
              SY NO 321/P, PLOT NO 1,D NO 401,
              <br />
              NARSINGI MAIN ROAD, RAJENDERNAGAR,
              <br /> NARSINGI, HYDERABAD,
              <br />
              RANGAREDDI, TELANGANA,
              <br /> INDIA, 500075.
              <br />
              <br />
              <span className="privacySpan">E-Mail: </span>
              wecare@agasthyanutromilk.com
              <br />
              <span className="privacySpan">Tel: </span>+ 91 73375 22452
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
