import React from "react";
import { Nav, NavLink, Bars, NavMenu, Img } from "./FooterElements";
import logo from "../../assets/logo-footer.png";

const Footer = () => {
  return (
    <>
      <Nav>
        <Bars />
        <Img src={logo} alt="image" />
        <br />
        <NavMenu>
          <NavLink to="/" activestyle>
            Home
          </NavLink>
          {/* <NavLink to="/about" activestyle>
            About Us
          </NavLink>
           */}
          <NavLink to="/privacy-policy" activestyle>
            Privacy Policy
          </NavLink>
          <NavLink to="/cancellation-and-refund" activestyle>
            Cancellation and Refund
          </NavLink>
          <NavLink to="/terms-of-use" activestyle>
            Terms of Use
          </NavLink>
          <NavLink to="/Shipping-and-delivery" activestyle>
            Shipping and Delivery
          </NavLink>
          <NavLink to="/contact" activestyle>
            Contact Us
          </NavLink>
        </NavMenu>
      </Nav>
      {/* <div style={{'background':'#000'}}>
		<h5 style={{'color':'#fff'}}>copyright</h5>
	</div> */}
    </>
  );
};

export default Footer;
