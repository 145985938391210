import React from 'react';
import Banner from '../components/Banner';

const About = () => {
	return(
		<Banner/>
		
	  );
};

export default About;
