import React from "react";
import logo from "../assets/logo.png";
const Privacy = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row">
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12" style={{ padding: "40px" }}>
          <h1>Privacy Policy</h1>
        </div>
      </div>
      {/* <img src={PrivacyBanner} alt="privacy-banner" className="bannerImg" /> */}
      <div className="container">
        <div className="row">
          <div>
            <h2>Agasthya Nutro Milk Privacy Policy</h2>

            <p>
              At Agasthya Nutro Milk, we are committed to safeguarding your
              privacy and ensuring the security of your personal information.
              This privacy policy outlines how we collect, use, disclose, and
              protect the data you provide to us when you interact with our
              dairy farm, visit our website, use our services, or participate in
              any related activities. By accessing and using our services, you
              consent to the practices described in this policy.
            </p>
            <p>
              <span className="privacySpan">
                1. Information Collection and Use:
              </span>{" "}
              We may collect personal information, such as your name, contact
              details, address, and payment information when you place an order,
              sign up for our newsletter, participate in promotions, or
              otherwise engage with us. This information is used solely for the
              purposes of providing you with our dairy products and services,
              processing your transactions, and improving your overall
              experience.
            </p>
            <p>
              <span className="privacySpan">
                2. Data Protection and Security:
              </span>{" "}
              We prioritize the security of your personal information and
              implement appropriate measures to protect it from unauthorized
              access, disclosure, alteration, or destruction. Access to your
              data is restricted to authorized personnel only, and we regularly
              update our security protocols to mitigate any potential risks
            </p>
            <p>
              <span className="privacySpan">
                3. Cookies and Tracking Technologies:
              </span>{" "}
              Our website may use cookies and similar tracking technologies to
              enhance your browsing experience and analyze website traffic.
              These tools collect non-personally identifiable information, such
              as IP addresses and browsing patterns, to improve our services and
              tailor content to your preferences. You can manage your cookie
              preferences through your browser settings.
            </p>
            <p>
              <span className="privacySpan">4. Sharing and Disclosure:</span> We
              We do not sell, trade, or rent your personal information to third
              parties without your explicit consent, except as required by law
              or for the fulfilment of our contractual obligations with you. We
              may share your data with trusted third-party service providers,
              such as shipping partners, payment processors, and marketing
              agencies, to ensure the seamless delivery of our products and
              services.
            </p>
            <p>
              <span className="privacySpan">5. Consent and Opt-Out:</span> By By
              providing your personal information, you consent to its
              collection, use, and disclosure in accordance with this policy. If
              you wish to withdraw your consent or opt-out of certain data
              processing activities, please contact us at below provided email
              address or phone number. However, opting out may limit your access
              to some of our services.
            </p>
            <p>
              <span className="privacySpan"> 6. External Links:</span> Our Our
              website may contain links to third-party websites or services.
              Please note that we are not responsible for the privacy practices
              of such external entities. We encourage you to review their
              respective privacy policies before engaging with them.
            </p>
            <p>
              <span className="privacySpan">7. Children's Privacy:</span> Our
              services are not intended for individuals under the age of 16. We
              do not knowingly collect personal information from children
              without parental consent. If you believe your child has provided
              us with personal information, please contact us immediately, and
              we will take appropriate steps to remove such data from our
              records.
            </p>
            <p>
              <span className="privacySpan">
                8. Changes to this Privacy Policy:
              </span>{" "}
              We may update this privacy policy from time to time to reflect
              changes in our practices or for legal, operational, or regulatory
              reasons. Any updates will be posted on our website, and the
              revised policy will have a new effective date.
            </p>
            <p>
              If you have any questions, concerns, or requests regarding your
              personal information or this privacy policy, please contact us at
              below provided email address or phone number. Your privacy matters
              to us, and we will do our best to address your inquiries promptly.
            </p>
            <p>
              Thank you for choosing Agasthya Nutro Milk. We appreciate your
              trust in us and remain committed to serving you with the highest
              level of privacy and data protection standards.
            </p>
            <p>
              <span className="privacySpan">Contact Information</span>
              <br />
              <br />
              <span className="privacySpan">
                Agasthya Nutro Milk Private Limited
              </span>
              <br />
              <br /> Corporate Identity Number: U74999TG2020PTC147101 <br />
              <br />
              <span className="privacySpan">Address: </span>
              <br />
              SY NO 321/P, PLOT NO 1,D NO 401,
              <br />
              NARSINGI MAIN ROAD, RAJENDERNAGAR,
              <br /> NARSINGI, HYDERABAD,
              <br />
              RANGAREDDI, TELANGANA,
              <br /> INDIA, 500075.
              <br />
              <br />
              <span className="privacySpan">E-Mail: </span>
              wecare@agasthyanutromilk.com
              <br />
              <span className="privacySpan">Tel: </span>+ 91 73375 22452
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
