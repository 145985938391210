import React from "react";
import logo from "../assets/logo.png";

const Delivery = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row">
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12" style={{ padding: "40px" }}>
          <h1>Shipping and Delivery</h1>
        </div>
      </div>
      {/* <img src={PrivacyBanner} alt="privacy-banner" className="bannerImg" /> */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Agasthya Nutro Milk Shipping and Delivery Policy</h2>

            <p>
              This Shipping and Delivery Policy outlines the terms and
              conditions governing the shipping and delivery of dairy products
              ordered through the Agasthya Nutro Milk App. Please read this
              policy carefully before placing your order.
            </p>
            <p>
              <span className="privacySpan">1. Order Processing:</span>
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                Once you place an order through the Milk App, our system will
                process it and send you an order confirmation via email or SMS
              </li>
              <li>
                Orders received before 8.00 pm will be processed on the same
                day. Orders received after 8.00 pm will be processed the next
                business day
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">2. Shipping Areas:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                We currently offer delivery services within Jubliee Hills,
                Manikonda, Narsing, Chitrapuri, OU colony near Golkonda.
              </li>
              <li>
                If your delivery address is not within our service area, you
                will receive a notification during the order placement process.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">3. Delivery Timeframes:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                Delivery timeframes may vary based on your location and the time
                of order placement.
              </li>
              <li>
                Our standard delivery hours are 5.00 am to 7.30 am - seven days
                a week. However, deliveries shall be done at the preferred time
                slot selected at the time of subscription
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">4. Delivery Process:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                Our delivery personnel will ensure that your dairy products are
                carefully packed and transported to your delivery address.
              </li>
              <li>
                You will receive a notification, including tracking information,
                once your order is out for delivery.
              </li>
              <li>
                Please ensure someone is available to receive the delivery
                during the specified delivery hours.{" "}
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">5. Contactless Delivery:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                In light of current health and safety concerns, we offer
                contactless delivery. Our delivery personnel will leave your
                order at your doorstep.
              </li>
              <li>
                You will receive a notification once the delivery has been made
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">6. Quality Assurance:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                We take utmost care to ensure the quality and freshness of our
                dairy products during transit.
              </li>
              <li>
                In case of any issues with the delivered products, please
                contact our customer support within maximum by 11.00 am of
                delivery. We may require photographic evidence to address the
                concern appropriately.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">7. Order Tracking:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                You can track the status of your order through the Agasthya
                Nutro Milk App. Please use the provided tracking information to
                get real-time updates on your delivery.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">
                8. Order Modifications and Cancellations:
              </span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                Order modifications or cancellations should be made latest by
                9.00 pm on the previous day Please review your order carefully
                before confirming.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">9. Force Majeure:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                In the event of unforeseen circumstances such as natural
                disasters, extreme weather conditions, or other force majeure
                events, delivery times may be affected. We will make our best
                efforts to inform you of any delays.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <p>
              <span className="privacySpan">10. Feedback and Support:</span>
              <br />
              <br />
            </p>
            <ul style={{ lineHeight: "2rem" }}>
              <li>
                We value your feedback. If you have any questions, concerns, or
                feedback regarding our shipping and delivery process, please
                contact our customer support team at
                wecare@agasthyanutromilk.com or call us at 8121001774 or
                6300565121.
              </li>
            </ul>
          </div>
          <p>
            By placing an order through the Agasthya Nutro Milk App, you
            acknowledge that you have read, understood, and agree to abide by
            the terms and conditions outlined in this Shipping and Delivery
            Policy
          </p>
          <p>
            Agasthya Nutro Milk App reserves the right to modify or update this
            policy at any time without prior notice. It is your responsibility
            to review this policy periodically.
          </p>
          <p>
            We look forward to serving you with high-quality dairy products and
            a seamless delivery experience.
          </p>
        </div>
      </div>
    </>
  );
};

export default Delivery;
