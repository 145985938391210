import React from "react";
import logo from "../assets/logo.png";

const SignUp = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row">
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12" style={{ padding: "40px" }}>
          <h1>Get in touch with us</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            style={{
              width: "100%",
              textAlign: "center",
              padding: "40px",
            }}
          >
            <h1>Agasthya Nutro Milk Private limited</h1>
            <h4>
              SY NO 321/P, PLOT NO 1,D NO 401,
              <br />
              NARSINGI MAIN ROAD, RAJENDERNAGAR,
              <br /> NARSINGI, HYDERABAD,
              <br />
              RANGAREDDI, TELANGANA,
              <br /> INDIA, 500075.
              <br />
              <br />
              <span className="privacySpan">E-Mail: </span>
              wecare@agasthyanutromilk.com
              <br />
              <span className="privacySpan">Tel: </span>+ 91 73375 22452
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
