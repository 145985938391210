import React from 'react';
import Banner from '../components/Banner';

const Events = () => {
	return(
		<Banner/>
		
	  );
};

export default Events;
