import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import About from "./views/about";
import Farms from "./views/farms";
import Contact from "./views/contact";
import Privacy from "./views/privacy";
import Terms from "./views/terms";
import Refund from "./views/refund";
import Delivery from "./views/delivery";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/farms" element={<Farms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/cancellation-and-refund" element={<Refund />} />
          <Route path="/Shipping-and-delivery" element={<Delivery />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
