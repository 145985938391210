import React from "react";
import logo from "../assets/logo.png";

const Terms = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row">
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12" style={{ padding: "40px" }}>
          <h1>Terms of use</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div>
            <h4>Agasthya Nutro Milk Terms of Use</h4>
            <p>
              Welcome to Agasthya Nutro Milk Private Limited
              (www.agasthyanutromilk.com). By accessing and using this Website,
              you agree to comply with and be bound by the following Terms of
              Use. If you do not agree with these terms, please refrain from
              using the Website.
            </p>
            <p>
              The terms of use is especially useful for clear and explicit
              guidelines for the protection of our service, our website & our
              users too
            </p>
            <p>
              <span className="privacySpan">1. Intellectual Property:</span>{" "}
              <br />
              All content and materials available on the Website, including but
              not limited to text, images, graphics, logos, audio, and video
              files, are the property of Agasthya Nutro Milk Private Limited or
              its licensors and are protected by applicable intellectual
              property laws. Kindly do not use, modify, distribute, reproduce,
              or create derivative works based on any content from this Website
              without prior written consent from Agasthya Nutro Milk Private
              Limited. Using, modifying, distributing, reproducing and or
              creating derivative works based on any content from this website
              without prior written consent from Agasthya Nutro Milk Private
              Limited, could lead the user’s account to be temporarily or
              suspended or permanently terminated.
            </p>
            <p>
              <span className="privacySpan">2. User Conduct:</span>
              <br /> You agree to use the Website in compliance with all
              applicable laws and regulations. When accessing or using the
              Website.
              <br />
              <p>Do’s & Don’t’s for users;</p>
              <p>Do’s :-</p>
              <p>You shall:-</p>
              <ul>
                <li>Comply with the terms mentioned herein.</li>
                <li>
                  Comply ith all applicable laws and regulations with respect to
                  your activities including, without limitation, privacy laws,
                  intellectual property laws, anti spam laws, tax laws and any
                  other regulatory requirements.
                </li>
                <li>
                  Comply with using this site in extremely professional and
                  efficient manner
                </li>
                <li>
                  Respect your privacy rights as well as privacy of others,
                  including intellectual property rights.
                </li>
                <li>Provide accurate information to us and keep us updated</li>
                <li>
                  Comply with any other action that maybe required to maintain
                  the commercial image of the company & brand identity.
                </li>
              </ul>
              <p>Don’ts:</p>
              <p>You shall not:</p>
              <ul>
                <li>• Act dishonestly , unprofessionally.</li>
                <li>
                  {" "}
                  engage in conduct such as hacking or use of malware that
                  affects the availability of the website.
                </li>
                <li>
                  {" "}
                  knowingly or negligently using the site in such a way that it
                  interferes or disrupts Agasthya Nutromilk’s network or its
                  services.
                </li>
                <li>
                  {" "}
                  Violate any existing applicable laws or regulations or any
                  other laws / rules that passed and are included from time to
                  time by Agasthya Nutro Milk Pvt Ltd.
                </li>
                <li>
                  {" "}
                  Engage in any unlawful, harmful, or fraudulent activities.
                </li>
                <li>
                  Post or transmit any content that is misleading,
                  misrepresenting, defrauding, offensive, defamatory, abusive,
                  or otherwise objectionable.
                </li>
                <li>
                  {" "}
                  Attempt to interfere with the proper functioning of the
                  Website.
                </li>
                <li>
                  Use the Website to distribute viruses, malware, or other
                  harmful software.
                </li>
                <li>
                  {" "}
                  Attempt to gain unauthorized access to any portion of the
                  Website or its related systems.
                </li>
                <li>
                  {" "}
                  Perform any other action not mentioned herein, but is
                  detrimental to the company and or brand image, in any manner
                  whatsoever.{" "}
                </li>
              </ul>
            </p>
            <p>
              <span className="privacySpan">3. User Accounts</span>
              <br /> Certain features or services on the Website may require
              user registration. If you create an account, you are responsible
              for maintaining the confidentiality of your account information
              and for all activities that occur under your account. You must
              promptly notify Agasthya Nutro Milk Private Limited of any
              unauthorized access or security breaches, Agasthya Nutro Milk Pvt
              Ltd shall put efforts to help user to resolve, but the user shall
              be solely responsible for its actions.
            </p>
            <p>
              <span className="privacySpan">4. Third-Party Links</span>
              <br />
              The Website may contain links to third-party websites or
              resources. Agasthya Nutro Milk Private Limited does not endorse,
              control, or assume responsibility for any content, products, or
              services provided by these third-party websites. Accessing these
              links is entirely at the users own risk, and user should review
              their respective Terms of Use and Privacy Policies of the third
              party links. Agasthya Nutro Milk would not be responsible any
              manner whatsoever.
            </p>
            <p>
              <span className="privacySpan">5. Disclaimer of Warranties</span>
              <br />
              The information and materials provided on the Website are for
              general informational purposes only. Agasthya Nutro Milk Private
              Limited makes no warranties, express or implied, regarding the
              accuracy, completeness, reliability, or suitability of the
              content. Use of the Website is at the sole responsibility of the
              user.
            </p>
            <p>
              <span className="privacySpan">6. Limitation of Liability</span>
              <br />
              In no event shall Agasthya Nutro Milk Private Limited or its
              affiliates be liable for any direct, indirect, incidental or
              co-incidental, consequential, or special damages arising out of or
              in connection with your use of the Website or its content. This
              includes, but is not limited to, any loss of data, revenue, or
              profits.
            </p>
            <p>
              <span className="privacySpan">7. Indemnification</span>
              <br />
              You agree to indemnify and hold harmless Agasthya Nutro Milk
              Private Limited and its affiliates from any claims, losses,
              damages, liabilities, and expenses (including legal fees) arising
              out of your use of the Website or any violation of these Terms of
              Use.
            </p>
            <p>
              <span className="privacySpan">8. Modifications</span>
              <br />
              Agasthya Nutro Milk Private Limited reserves the right to modify
              or update these Terms of Use at any time. By continuing to use the
              Website after the posting of any changes, you accept and agree to
              the modified terms.
            </p>
            <p>
              <span className="privacySpan">9. Governing Law</span>
              <br />
              These Terms of Use shall be governed by and construed in
              accordance with the laws of Telangana, INDIA, without regard to
              its conflict of law principles.
            </p>
            <p>
              <span className="privacySpan">10. Contact Us</span>
              <br />
              If you have any questions or concerns regarding these Terms of
              Use, please contact us at wecare@agasthyanutromilk.com.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
