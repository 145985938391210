import React from "react";
import BannerWeb from "../assets/banner-web.png";
import Milk from "../assets/milk.png";
import Veggi from "../assets/veggi.png";
import Eggs from "../assets/eggs.png";
import Fruits from "../assets/fruits.png";
import Cereals from "../assets/cereals.png";
import logo from "../assets/logo.png";
import AgasthyaBanner from "../assets/web.jpg";
const Home = () => {
  return (
    <>
      <div className="container homeContainer">
        <div className="row" style={{ marginBottom: "70px" }}>
          <div className="c12 textcenter">
            <img src={logo} alt="image" className="logo" />
          </div>

          <h1 className="bannerH1">
            <span className="bannerTextPrimary">
              Where Nature’s Bounty Blossoms
            </span>
            <br />
            Embrace the Purest Delights of Our Organic Dairy Farm
          </h1>
          <p className="bannerTextParagraph">
            Nestled amidst picturesque landscapes, our organic dairy farm stands
            as a haven of wholesome goodness and sustainable practices. We
            believe that nature’s rhythm guides us towards creating the purest
            and most nourishing dairy products{" "}
          </p>
          <div className="c12 textcenter">
            <button className="btn">Discover More</button>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          background: "#f2f2f2",
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="row">
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2 className="headingtxt">
              Embrace the Harvest: Discover the Delight of Farm-to-Fresh
              Products
            </h2>
          </div>
        </div>
        <div className="row desktoponly">
          <div className="c3">
            <div className="card ">
              <div className="cardinner">
                <div>
                  <img src={Milk} alt="BannerWeb" className="image" />
                </div>
                <div className="cardtext">
                  <h2>Milk</h2>
                  <p>
                    Pure goodness in every sip! Enjoy the wholesome richness of
                    fresh organic buffalo and gir cow milk. Sourced from happy,
                    well-tended animals, our milk is free from additives for a
                    truly natural taste.
                  </p>
                </div>
                <div className="c12 textcenter">
                  <button className="btn">Discover More</button>
                </div>
              </div>
            </div>
          </div>
          {/* ----- */}
          <div className="c3">
            <div className="card">
              <div className="cardinner">
                <div>
                  <img src={Veggi} alt="BannerWeb" className="image" />
                </div>
                <div className="cardtext">
                  <h2>Fresh Vegitables</h2>
                  <p>
                    Savor the goodness of nature's bounty, knowing that each
                    bite not only nourishes your body but also contributes to a
                    greener, healthier world with our very fresh organic
                    vegitables
                  </p>
                </div>
                <div className="c12 textcenter">
                  <button className="btn">Discover More</button>
                </div>
              </div>
            </div>
          </div>
          {/* ----- */}
          <div className="c3">
            <div className="card">
              <div className="cardinner">
                <div>
                  <img src={Fruits} alt="BannerWeb" className="image" />
                </div>
                <div className="cardtext">
                  <h2>Seasonal Fruits</h2>
                  <p>
                    Grown with love and sustainable practices, our fruits offer
                    a delectable taste that only nature can provide. Embrace the
                    wholesome nourishment and support eco-friendly farming for a
                    healthier you and a greener world.
                  </p>
                </div>
                <div className="c12 textcenter">
                  <button className="btn">Discover More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row desktoponly">
          <div className="c3">
            <div className="card">
              <div className="cardinner">
                <div>
                  <img src={Cereals} alt="BannerWeb" className="image" />
                </div>
                <div className="cardtext">
                  <h2>Breakfast Cereals</h2>
                  <p>
                    Treat yourself to a breakfast that not only fuels your body
                    but also feeds your soul. Embrace the essence of nature in
                    every bite and start your day with farm-fresh organic
                    cereals a perfect harmony of taste, nutrition.
                  </p>
                </div>
                <div className="c12 textcenter">
                  <button className="btn">Discover More</button>
                </div>
              </div>
            </div>
          </div>
          {/* ----- */}
          <div className="c3">
            <div className="card">
              <div className="cardinner">
                <div>
                  <img src={Eggs} alt="BannerWeb" className="image" />
                </div>
                <div className="cardtext">
                  <h2>Country Eggs</h2>
                  <p>
                    Eggs help in 30% more absorption of immunity boosters.
                    Nourished by nature, our hens enjoy chemical-free, non-GMO
                    feed and produces delicious organic eggs for a healthier
                    you.
                  </p>
                </div>
                <div className="c12 textcenter">
                  <button className="btn">Discover More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mobileonly">
          <div className="row">
            <div className="mc1 textcenter">
              <img src={Milk} alt="BannerWeb" className="image" />
              <h4>Milk</h4>
            </div>
            <div className="mc1 textcenter">
              <img src={Veggi} alt="BannerWeb" className="image" />
              <h4>Fresh Vegitables</h4>
            </div>
            <div className="mc1 textcenter">
              <img src={Fruits} alt="BannerWeb" className="image" />
              <h4>Seasonal Fruits</h4>
            </div>
            <div className="mc1 textcenter">
              <img src={Cereals} alt="BannerWeb" className="image" />
              <h4>Breakfast Cereals</h4>
            </div>
            <div className="mc1 textcenter">
              <img src={Eggs} alt="BannerWeb" className="image" />
              <h4>Country Eggs</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container appbanner">
        <img src={BannerWeb} alt="BannerWeb" style={{ width: "100%" }} />
      </div>
      <div className="c12" style={{ background: "#ffe7c7" }}>
        <div className="container">
          <div className="c12">
            <div style={{ padding: "20px" }}>
              <p
                className="textcenter"
                style={{ fontSize: "22px", textAlign: "justify" }}
              >
                At our company, we take great pride in sourcing the finest cow
                milk and buffalo milk, along with fresh eggs, fruits,
                vegetables, and cereals, all directly from our very own organic
                farms. With an unwavering commitment to quality, we leave no
                stone unturned in ensuring that every product meets the highest
                standards.
              </p>
              <p
                className="textcenter"
                style={{ fontSize: "22px", textAlign: "justify" }}
              >
                Our rigorous quality tests guarantee that you receive only the
                best, most wholesome items. Our cattle and hens are cared for
                with utmost attention, fed with nutritious and nourishing feed,
                resulting in the purest and healthiest produce. When it comes to
                delivering your daily essentials, we go the extra mile by
                providing them to you without any preservatives or mixing,
                giving you peace of mind and the assurance of unadulterated
                goodness in every package.
              </p>

              <p
                className="textcenter"
                style={{
                  fontSize: "42px",
                  textAlign: "center",
                  color: "#d8877f",
                }}
              >
                Discover the Power of Our Mobile App: Unbeatable Features for
                Your Convenience
              </p>
              <ul style={{ listStyle: "square" }}>
                <li style={{ fontSize: "22px", lineHeight: "3rem" }}>
                  Get farm-fresh essentials: milk, veggies, fruits, and eggs
                  delivered right to your door!
                </li>
                <li style={{ fontSize: "22px", lineHeight: "3rem" }}>
                  Tailor your ideal subscription plan, personalized to suit your
                  unique needs.
                </li>
                <li style={{ fontSize: "22px", lineHeight: "3rem" }}>
                  Indulge in a hassle-free getaway with our milk ordering
                  vacation mode: Temporarily suspend deliveries while you're
                  away.
                </li>
                <li style={{ fontSize: "22px", lineHeight: "3rem" }}>
                  Unleash the excitement with thrilling offers and massive
                  savings!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="c12">
        <div className="container">
          <div className="c12">
            <div className="row">
              <div className="c12 textcenter" style={{ padding: "40px" }}>
                <h1>Agasthya Nutro Milk Private limited</h1>
                <h4>
                  SY NO 321/P, PLOT NO 1,D NO 401,
                  <br />
                  NARSINGI MAIN ROAD, RAJENDERNAGAR,
                  <br /> NARSINGI, HYDERABAD,
                  <br />
                  RANGAREDDI, TELANGANA,
                  <br /> INDIA, 500075.
                  <br />
                  <br />
                  <span className="privacySpan">E-Mail: </span>
                  wecare@agasthyanutromilk.com
                  <br />
                  <span className="privacySpan">Tel: </span>+ 91 73375 22452
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c12">
        <img src={AgasthyaBanner} alt="BannerWeb" style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default Home;
