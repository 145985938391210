import React from 'react';
import BannerImage from '../../assets/Home_banner.png';

const Banner = () => {
    return (
        <>
            <div className='bannerdiv'>
                <img src= {BannerImage} alt='bannerimage' />
            </div>
        </>
    );
};

    export default Banner;